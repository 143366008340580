import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import Vuex from 'vuex';
import * as VueGoogleMaps from 'vue2-google-maps';

// import * as VueGoogleMaps from 'vue2-google-maps';
import Notifications from 'vue-notification';
// import Vue2TouchEvents from 'vue2-touch-events'
import { PaginationPlugin } from 'bootstrap-vue';

import bootstrapdatetimepicker from 'bootstrap-datetimepicker-npm';

import main from './store/main';
import getHelpers from './helpers';
import initRest from '72cms-orm/datasources/Rest';

const getImagePath = getHelpers('getImagePath');
const getAssetPath = getHelpers('getAssetPath');
const formatString = getHelpers('formatString');

_.mixin({
    imgPath: getImagePath,
    assetPath: getAssetPath,
    str: formatString,
    moment: moment,
});

const libs = {};

window.moment = moment;
window._ = _;
window.Vue = Vue;
window.Vuex = Vuex;
window.exportLib = (key, value) => {
    libs[key] = value;
}

window.importLib = (key) => {
    return libs[key];
}

Vue.use(Vuex);
Vue.use(Notifications);
// Vue.use(Vue2TouchEvents);
Vue.use(PaginationPlugin);
// Vue.use(SkeletonPlugin);
// Vue.use(VueGoogleMaps, {
//     load: {
//         key: window.env.GOOGLE_MAP_KEY,
//         libraries: "places" // necessary for places input
//     }
// });
Vue.use(VueGoogleMaps, {
    load: {
        key: window.env.GOOGLE_MAP_KEY,
        libraries: "places" // necessary for places input
    }
});

var store = new Vuex.Store(main);
window.store = store;
window.mountVueComponent = (id, component) => {
    var root = document.getElementById(id);
    if (root) {
        var data = null;
        var config = null;

        try {
            var content = root.textContent;
            content = JSON.parse(content);
            data = content.data || null;
            config = content.config || null;

        } catch (e) {
            console.info(`Problem parsing data and config for component ${id}`);
        }

        new Vue({
            el: root,
            store,
            render: h => h(component, {
                props: { initialData: data, config }
            }),
        })
    }
}

window.exportLib('lodash', _);
window.exportLib('moment', moment);
window.exportLib('Vuex', Vuex);
window.exportLib('getHelpers', getHelpers);
window.exportLib('api', initRest({ ...env }));
window.dispatchEvent(new CustomEvent('vue-init'));

_.chain(document.querySelectorAll('script[data-src]'))
    .map((el) => {
        el.src = el.dataset.src;
    })
    .value();

/**
 * GoogleMap init
 *
 */
 window.vueGoogleMapsInitOld = window.vueGoogleMapsInit
 window.vueGoogleMapsInit = (value) => {
     // google is now available to global scope
 
     window.dispatchEvent(new CustomEvent('gmap-init'));
     return window.vueGoogleMapsInitOld(value);
 }
 
 window.recaptchaInit = function () {
     // $(".g-recaptcha").each(function() {
     //     var el = $(this);
     //     if (grecaptcha) {
     //         grecaptcha.render($(el).attr("id"), {});
     //     }
     // });
     // console.log('callback');
     window.dispatchEvent(new CustomEvent('recaptcha-init'));
 
 }
