import U from 'urijs';
import ItemResource from './Item';
import CollectionResource from './Collection';
import Pagination from '../utils/Pagination';
import Query from '../utils/Query';

export default class Relation {
    constructor(name, config, root) {
        this.name = name;

        if (!root instanceof ItemResource) {
            throw new Error('Relation root has to be an item resource');
        }

        this.root = root;
        this.datasource = this.root.datasource;

        this.config = config;
        this.type = _.get(config, 'type');
    }

    getEndpoint() {
        let rootEndpoint = this.root.getEndpoint();
        return `${rootEndpoint}/${this.name}`;
    }

    get(query = null, pagination = null, additional = {}) {
        let params = {};

        if (query) {
            query = (query instanceof Query) ?
                query : new Query(
                    _.get(query, 'filter', {}),
                    _.get(query, 'sort', {}),
                    _.get(query, 'search', '')
                );
            query = query.get();
            query[`search[${this.type}]`] = query.search || query[`search[${this.type}]`];
            query.search = undefined;
        }

        if (pagination) {
            pagination = (pagination instanceof Pagination) ?
                pagination : new Pagination(
                    _.get(pagination, 'page', 1),
                    _.get(pagination, 'per_page', 0),
                    _.get(pagination, 'total', 0)
                );
            pagination = pagination.get();
        }

        params = _.assign(params, query, pagination, additional);

        return this.datasource
            .getData(this.getEndpoint(), params)
            .then(res => {
                if (ItemResource.test(res)) {
                    return ItemResource.fromJsonApi(res, this.datasource);
                }

                if (CollectionResource.test(res)) {
                    return CollectionResource.fromJsonApi(res, this.datasource);
                }

                throw new Error(`Invalid response for relation "${this.name}"`);
            })
            .then(res => {
                res.belongsTo = this;
                return res;
            })
    }
}

