import _ from 'lodash';
import Promise from 'bluebird';
import getHelpers from '../helpers';
const api = getHelpers('api');

// initial state
const state = {
    favorite_product_entries: [],
    notifications: [],
    ...window.store,
    timestamp: Date.now(),
}

// getters
const getters = {
    customer: (state) => state.customer,
    notifications: (state) => state.notifications,
    favorite_product_entries: (state) => state.favorite_product_entries,
    timestamp: (state) => state.timestamp,
    route: (state) => (name, params) => {
        let path = '/' + _.get(state, ['routes', name]);

        _.forEach(params, (value, key) => {
            path = _.replace(path, new RegExp(`{${key}[?]?}`), value);
        })

        return path;
    }
}

const actions = {
    updateProfile({ commit }, data) {
        return api
            .patchData(`/my/profile`, {}, data)
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Profile updated successfully',
                });
                commit('updateProfile', res);
                return res;
            })
    },

    updateCustomer({ commit, state }, data) {
        const customerId = _.get(state, 'customer.id');
        return api
            .patchData(`/my/customer/${customerId}`, {}, { origin: data })
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Profile updated successfully',
                });
                commit('updateCustomer', res);
            })
    },

    addNotification({ commit }, payload) {
        commit('addNotification', payload);
    },
}

// mutations
const mutations = {

    updateProfile(state, response) {
        _.set(state, 'customer', response);
        _.set(state, 'timestamp', Date.now());
    },

    updateCustomer(state, response) {
        _.set(state, 'customer', response);
        _.set(state, 'timestamp', Date.now());
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
