import _ from 'lodash';
import flat from 'flat';

export default class Query {
    constructor(filter, sort, search, additionals) {
        this.filter = filter;
        this.sort = sort;
        this.search = search;
        this.additionals = additionals || {};
        this.fetching = false;
    }

    set(key, value) {
        key = _.isArray(key) ? key : _.split(key, '.');
        let firstKey = _.first(key)

        if (_.has(this, firstKey)) {
            _.set(this, key, value);
        } else {
            _.set(this.additionals, key, value);
        }

        return this;
    }

    get() {
        return {
            filter: Query.serializeQuery(this.filter),
            sort: Query.serializeQuery(this.sort),
            search: this.search,
            ...Query.serializeAdditionals(this.additionals),
        }
    }
}

Query.serializeAdditionals = (additionals, field = null) => {
    let qs = flat(additionals);

    return _.chain(qs)
        .toPairs()
        .map(([k, v]) => {
            let key = _.split(k, '.')
                .map((piece, idx) => idx == 0 ? piece : `[${piece}]`)
                .join('');

            return [key, v];
        })
        .fromPairs()
        .value();
}

Query.serializeFilter = (filterObject) => {
    let filterName = _.get(filterObject, 'name', '');
    let filterArgs = _.get(filterObject, 'args', []);

    if (_.isEmpty(filterArgs)) {
        return filterName;
    }

    return `${filterName}(${_.join(filterArgs, ',')})`;
}

Query.deserializeFilter = (filterString) => {
    queryString = decodeURIComponent(queryString);
    let match = filterString.match(/^([^()]+)\(([^()]+)\)/);
    if (match) {
        return {
            name: match[1],
            args: _.split(match[2], ','),
        }
    }

    return { name: filterString }
}

Query.serializeQuery = (queryObject) => {
    return _.toPairs(queryObject)
        .reduce((tmp, keyValuePair) => {
            const [key, value] = keyValuePair;

            return [...tmp, value ? `${key}:${value}` : key]
        }, [])
        .join(';')
}

Query.deserializeQuery = (queryString) => {
    queryString = decodeURIComponent(queryString);

    return _.chain(queryString)
        .split(/[;]/)
        .filter(Boolean)
        .reduce((tmp, keyValueString) => {
            const [key, value] = keyValueString.split(/[\=\:]/);
            if (!key) return tmp;

            return { ...tmp, [key]: value || null }
        }, {})
        .value();
}

// filter_active=1&filter_id=$null
Query.fromQueryString = (qs) => {
    let params;
    if (params instanceof URLSearchParams) {
        params = qs;
    } else {
        params = new URLSearchParams(qs);
    }

    params = _.chain(Array.from(params.entries()))
        .reduce((tmp, [key, value]) => {
            let filter = tmp.filter || {};
            let sort = tmp.sort || {};
            let search = tmp.search || '';

            try {
                key = decodeURIComponent(key);
                value = decodeURIComponent(value);
            } catch (e) {
                return tmp;
            }

            if (_.startsWith(key, 'filter_')) {
                let filterKey = key.replace(/^filter_/, '');
                if (value) {
                    filter[filterKey] = value;
                }
            } else if (_.startsWith(key, 'sort_')) {
                let sortKey = key.replace(/^sort_/, '');
                if (value) {
                    sort[sortKey] = value;
                }
            } else if (key == 'search') {
                if (value) {
                    search = value;
                }
            } else if (key == 'query') {
                if (value) {
                    search = value;
                }
            } else {
                return tmp;
            }

            return _.merge(tmp, {
                filter, sort, search,
            });

        }, {}).value()

    let query = new Query(
        params.filter,
        params.sort,
        params.search,
    )

    return query;
}

Query.fromMeta = (meta) => {
    const filter = _.get(meta, 'filter', '');
    const sort = _.get(meta, 'sort', '');
    let search = _.get(meta, 'search', '');

    if (_.isObject(search)) {
        search = _.get(search, meta.endpoint);
    }

    return new Query(
        Query.deserializeQuery(filter),
        Query.deserializeQuery(sort),
        decodeURIComponent(search)
    )
}

