import * as FeatherIcons from 'vue-feather-icons';

export default {
    items: [
        {
            name: 'Dashboard',
            type: 'dashboard',
            route: 'dashboard',
            color: 'navy',
            group: 'general',
            iconComponent: FeatherIcons.GridIcon,
        },
        {
            name: 'Content',
            children: [
                { name: 'Layouts', route: 'layouts', type: 'layout', },
                { name: 'Pages', route: 'pages', type: 'page' },
                { name: 'Panel library', route: 'panels', type: 'panel' },
            ],
            color: 'navy',
            group: 'general',
            iconComponent: FeatherIcons.LayersIcon,
        },
        {
            name: 'Assets',
            route: 'assets/0',
            type: 'asset',
            color: 'navy',
            group: 'general',
            iconComponent: FeatherIcons.ImageIcon,
        },
        {
            name: 'Products',
            route: 'products',
            type: 'product',
            color: 'navy',
            group: 'Catalogue',
            iconComponent: FeatherIcons.PackageIcon,
        },
        {
            name: 'Categories',
            route: 'categories',
            type: 'category',
            color: 'navy',
            group: 'Catalogue',
            iconComponent: FeatherIcons.TagIcon,
        },
        {
            name: 'News',
            children: [
                { name: 'Posts', route: 'news_posts', type: 'news_post' },
                { name: 'Categories', route: 'news_categories', type: 'news_category' },
            ],
            color: 'navy',
            group: 'media',
            iconComponent: FeatherIcons.FileTextIcon,
        },  
        {
            group: 'Team',
            name: 'Team',
            route: 'staff_members',
            iconClass: 'material-icons',
            iconName: 'sentiment_satisfied_alt',
            color: 'navy',
            iconComponent: FeatherIcons.UsersIcon,
        },
/*
        {
            group: 'Inta-Ag',
            name: 'Inta-Ag Mag',
            route: 'files',
            iconClass: 'material-icons',
            iconName: 'sentiment_satisfied_alt',
            color: 'navy',
            iconComponent: FeatherIcons.BookIcon,
        },*/
        /*{
            name: 'Testimonials',
            route: 'testimonials',
            iconClass: 'material-icons',
            // iconName: '',
            color: 'navy',
            group: 'client',
            iconComponent: FeatherIcons.MessageSquareIcon,
        },*/
        
        {
            name: 'Enquiries',
            children: [
                { name: 'Enquiries', route: 'enquiries', type: 'enquiry' },
                { name: 'Subscribers', route: 'subscribers', type: 'subscriber' },
            ],
            color: 'navy',
            group: 'client',
            iconComponent: FeatherIcons.MailIcon,
        },
        {
            name: 'Accounts',
            children: [
                { name: 'Users', route: 'users' },
                { name: 'Roles', route: 'roles' },
            ],
            // iconName: '',
            color: 'navy',
            group: 'settings',
            iconComponent: FeatherIcons.LockIcon,
        },
        {
            name: 'Settings',
            children: [
                { name: 'Settings', route: 'settings', type: 'setting' },
                { name: 'Redirect URLs', route: 'redirect_urls', type: 'redirect_url' },
            ],
            color: 'navy',
            group: 'settings',
            iconComponent: FeatherIcons.SettingsIcon,
        }
    ],
    defaultItem: 'Content',
}
