import V from 'voca';

function formatType(value) {
    const formatted = value.split('.').join(' ').replace('_', ' ');
    return V.titleCase(formatted);
}

export default {
    'page.create': 'Page has been created successfully',
    'page.update': 'Page has been updated',
    'page.delete': 'Page has been deleted',
    'page.updateBatch': 'All done!',

    'panel.update': 'Panel has been updated',
    'panel.create': 'Panel has been created successfully',
    'panel.update': 'Panel has been updated',
    'panel.delete': 'Panel has been deleted',
    'panel.updateBatch': 'All done!',

    default(type, method) {
        let action = method == 'updateBatch' ? 'update' : method;
        if (type) return `${formatType(type)} ${action} has been completed`;
        return `${action} successfully`;
    }
}
