import U from 'urijs';
import Pagination from '../utils/Pagination';
import Query from '../utils/Query';
import ItemResource from './Item';

export default class CollectionResource {

    constructor(type, datasource) {
        this.type = type;
        this.datasource = datasource;
        this.data = [];
    }

    getUrl() {
        return _.get(this, ['links', 'self'], null);
    }

    getEndpoint() {
        return '/' + _.get(this, ['meta', 'endpoint'], `${this.type}`);
    }

    getQuery(options = {}) {
        let query = Query.fromMeta(_.get(this, 'meta', {}), options)

        return query;
    }

    getPagination(options = {}) {
        let pagination = Pagination.fromMeta(_.get(this, 'meta', {}), options)

        return pagination;
    }

    getItem(id) {
        return this.datasource
            .getData(`${this.getEndpoint()}/${id}`)
            .then((res) => {
                let item = new ItemResource(this.type, this.datasource);

                return _.assign(item, res);
            })
    }

    get(query = null, pagination = null, additional = {}) {
        let params = {};

        if (query) {
            query = (query instanceof Query) ?
                query : new Query(
                    _.get(query, 'filter', {}),
                    _.get(query, 'sort', {}),
                    _.get(query, 'search', ''),
                    _.get(query, 'additionals', {})
                );
            query = query.get();
            query[`search[${this.type}]`] = query.search || query[`search[${this.type}]`];
            query.search = undefined;
        }

        if (pagination) {
            pagination = (pagination instanceof Pagination) ?
                pagination : new Pagination(
                    _.get(pagination, 'page', 1),
                    _.get(pagination, 'per_page', 0),
                    _.get(pagination, 'total', 0)
                );
            pagination = pagination.get();
        }

        params = _.assign(params, query, pagination, additional);

        return this.datasource
            .getData(this.getEndpoint(), params)
            .then(res => {
                if (CollectionResource.test(res)) {
                    return CollectionResource.fromJsonApi(res, this.datasource);
                }

                throw new Error('Bad format for collection resource');
            })
    }

    update(payload) {
        return this.datasource
            .putData(payload)
            .then(res => Collection.fromJsonApi(res));
    }
}

CollectionResource.fromJsonApi = (res, datasource) => {
    const type = _.get(res, 'type', null);
    let collection = new CollectionResource(type, datasource);

    collection = _.assign(collection, res);
    collection.data = _.map(collection.data, (item) => ItemResource.fromJsonApi(item))

    return collection;
}

CollectionResource.test = (res) => {
    return _.get(res, 'data')
        && _.get(res, 'type')
        && _.isArray(res.data);
}

