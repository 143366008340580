import menu from './menu';
import notifications from './notifications';

export default {
    rootPath: window.env.APP_URL,
    cmsPath: '/admin',
    cmsTitle: window.env.APP_NAME,
    routes: {
        default: '/dashboard',
        endpoints: {
            '^/pages': {
                params: { filter: 'parent_id:$null' },
            },
            '^/panels': {
                params: { filter: 'content_id:$null' },
            },
            '^/categories': {
                params: { filter: 'parent_id:$null' },
            },
            '^/news_posts': {
               params: {
                   sort: 'position:desc'
               }
            },
            
            '^/enquiries': {
                params: { sort: 'created_at:desc' }
            },
            '^/subscribers': {
                params: { sort: 'created_at:desc' }
            },
        }
    },
    file: {
        extensions: {
            img: ['png', 'jpg', 'jpeg', 'gif', 'svg'],
            doc: ['doc', 'docx'],
            pdf: ['pdf'],
            xls: ['xls', 'xlsx'],
            csv: ['csv'],
        }
    },
    menu,
    notifications,
    inboxActive: false,
}
