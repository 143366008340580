import U from 'urijs';
import RelationResource from './Relation';

export default class ItemResource {
    constructor(type, datasource) {
        this.type = type;
        this.datasource = datasource;
    }

    getUrl() {
        return _.get(this, ['links', 'self'], null);
    }

    getEndpoint(id = null) {
        let rid = id || this.id || '_';

        if (this.getUrl()) {
            return U(this.getUrl())
                .path()
                .split('/')
                .slice(0, -1)
                .concat([rid])
                .join('/');
        }

        return _.join(['', this.type, rid], '/');
    }

    getRelations() {
        return _.chain(this.schema)
            .get('relationships', [])
            .toPairs()
            .map(([name, rel]) => {
                return new RelationResource(name, rel, this);
            })
            .value();
    }

    getRelation(name) {
        return _.chain(this.getRelations())
            .find(rel => rel.name === name)
            .value();
    }

    setAttr(attributes, value = null) {
        if (_.isObject(attributes)) {
            this.attributes = _.assign({}, this.attributes, attributes);
        } else if (!_.isUndefined(value)) {
            _.set(this.attributes, attributes, value);
        }

        item = new ItemResource(this.type, this.datasource);
        return _.assign(item, this);
    }

    getAttr(name) {
        return _.get(this.attributes, name);
    }

    get(id) {
        return this.datasource
            .getData(this.getEndpoint(id), {})
            .then((res) => ItemResource.fromJsonApi(res, this.datasource));
    }

    delete() {
        return this.datasource
            .delData(this.getEndpoint(this.id), {})
            .then((res) => ItemResource.fromJsonApi(res, this.datasource));
    }

    update(attributes) {
        return this.datasource
            .patchData(this.getEndpoint(this.id), {}, attributes)
            .then((res) => ItemResource.fromJsonApi(res, this.datasource));
    }

    create(attributes) {
        return this.datasource
            .postData(this.getEndpoint(), {}, attributes)
            .then((res) => ItemResource.fromJsonApi(res, this.datasource));
    }
}

ItemResource.fromJsonApi = (res, datasource) => {
    const type = _.get(res, 'type', null);
    const item = new ItemResource(type, datasource);

    return _.assign(item, res, {
        _original: _.cloneDeep(_.get(res, 'attributes', {})),
    });
}

ItemResource.test = (res) => {
    return _.get(res, 'id') && _.get(res, 'type');
}

