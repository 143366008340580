import _ from 'lodash';

export default class Pagination {
    constructor(page, per_page, total = 0) {
        this.page = _.parseInt(page);
        this.per_page = _.parseInt(per_page);
        this.total = _.parseInt(total);
        this.fetching = false;
    }

    set(key, value) {
        _.set(this, key, value);
        return this;
    }

    hasMore() {
        let offset = this.total - this.page * this.per_page;
        return _.max([offset, 0]);
    }

    from() {
        return _.min([(this.page - 1) * this.per_page + 1, this.total]);
    }

    to() {
        return _.min([this.page * this.per_page, this.total]);
    }

    next() {
        let nextPage = this.page + 1;

        return nextPage > this.last() ?
            Pagination(nextPage, this.per_page, this.total) : null;
    }

    prev() {
        let prevPage = this.page - 1;

        return prevPage > 0 ?
            Pagination(prevPage, this.per_page, this.total) : null;
    }

    last() {
        const last = Math.floor(this.total / this.per_page);

        return {
            page: last,
            per_page: this.per_page,
        }
    }

    first() {
        return {
            page: 1,
            per_page: this.per_page,
        }
    }

    get() {
        return {
            page: this.page,
            per_page: this.per_page,
        }
    }
}

Pagination.fromQueryString = (qs) => {
    let params = new URLSearchParams(qs);

    let pagination = new Pagination(
        params.get('page') || 1,
        params.get('per_page') || 15,
        params.get('total') || 0
    )

    return pagination;
}

Pagination.fromMeta = function (meta) {
    return new Pagination(
        _.get(meta, 'current_page', 1),
        _.get(meta, 'per_page', 15),
        _.get(meta, 'total', 0)
    )
}

